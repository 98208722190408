
.btn-white{
  color: $secondary !important;
}

.btn-secondary{
  color: $white !important;
  padding: 12px 32px;
  &:before{
	content:"";
	position: relative;
	float: right;
	top:8px;
	width: 12px;
	height: 12px;
	margin-left: 8px;
	background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 13'><path fill='#{$white}' fill-rule='evenodd' d='M1.22.72a.833.833 0 0 1 1.18 0l9.183 9.185V4.416a.833.833 0 1 1 1.667 0v7.5c0 .46-.373.834-.834.834h-7.5a.833.833 0 1 1 0-1.667h5.489L1.22 1.9a.833.833 0 0 1 0-1.178Z' clip-rule='evenodd'/></svg>"));
  }
}

header{
  .btn-secondary{
	&:before{
		display: none;
	}
  }
}

.link-white{
  &:before{
	content:"";
	position: relative;
	float: right;
	top:8px;
	width: 12px;
	height: 12px;
	margin-left: 8px;
	background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 13'><path fill='#{$white}' fill-rule='evenodd' d='M1.22.72a.833.833 0 0 1 1.18 0l9.183 9.185V4.416a.833.833 0 1 1 1.667 0v7.5c0 .46-.373.834-.834.834h-7.5a.833.833 0 1 1 0-1.667h5.489L1.22 1.9a.833.833 0 0 1 0-1.178Z' clip-rule='evenodd'/></svg>"));
  }
}

.link-dark{
  &:before{
	content:"";
	position: relative;
	float: right;
	top:2px;
	width: 24px;
	height: 25px;
	margin-left: 8px;
	background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 25'><path fill='#000' fill-opacity='.85' fill-rule='evenodd' d='M5.066 5.565a1 1 0 0 1 1.415 0L17.5 16.586V10a1 1 0 1 1 2 0v9a1 1 0 0 1-1 1h-9a1 1 0 0 1 0-2h6.586L5.067 6.98a1 1 0 0 1 0-1.415Z' clip-rule='evenodd'/></svg>"));
  }
}

.link-secondary{
  &:before{
	content:"";
	position: relative;
	float: right;
	top:8px;
	width: 12px;
	height: 12px;
	margin-left: 8px;
	background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 13'><path fill='#{$secondary}' fill-rule='evenodd' d='M1.22.72a.833.833 0 0 1 1.18 0l9.183 9.185V4.416a.833.833 0 1 1 1.667 0v7.5c0 .46-.373.834-.834.834h-7.5a.833.833 0 1 1 0-1.667h5.489L1.22 1.9a.833.833 0 0 1 0-1.178Z' clip-rule='evenodd'/></svg>"));
  }
}

.nav-header{
  .nav-item:hover > .dropdown-menu{
	display: block;
  }
}

.nav-header{
  .nav-item{
	.nav-link{
	  border-radius:40px;
	  text-decoration: none;
	  &.active{
		background-color: $primary;
		color: $love-purple;
		&:before{
		  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 21'><path fill='#{$love-purple}' fill-rule='evenodd' d='M4.826 7.41a.833.833 0 0 1 1.179 0l4.41 4.411 4.411-4.41a.833.833 0 0 1 1.179 1.178l-5 5a.833.833 0 0 1-1.179 0l-5-5a.833.833 0 0 1 0-1.178Z' clip-rule='evenodd'/></svg>"));
		}
	  }
	}
	&:hover{
	  .nav-link{
		background-color: $primary;
		color: $love-purple-200;
		&:before{
		  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 21'><path fill='#{$love-purple-200}' fill-rule='evenodd' d='M4.826 7.41a.833.833 0 0 1 1.179 0l4.41 4.411 4.411-4.41a.833.833 0 0 1 1.179 1.178l-5 5a.833.833 0 0 1-1.179 0l-5-5a.833.833 0 0 1 0-1.178Z' clip-rule='evenodd'/></svg>"));
		}
	  }
	}
  }
  .nav-modules, .nav-ressources{
	.btn-menu-desktop{
	  transition: all ease-in-out 0.2s;
	  &:hover{
		transition: all ease-in-out 0.2s;
		background-color: $love-purple-200;
		a{
		  color: $primary;
		}
	  }
	}
  }
}
.dropdown-toggle{
  &:after{
	display: none;
  }
  &:before{
	content:"";
	position: relative;
	float: right;
	top:2px;
	width: 24px;
	height: 25px;
	margin-left: 4px;
	background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 21'><path fill='#{$white}' fill-rule='evenodd' d='M4.826 7.41a.833.833 0 0 1 1.179 0l4.41 4.411 4.411-4.41a.833.833 0 0 1 1.179 1.178l-5 5a.833.833 0 0 1-1.179 0l-5-5a.833.833 0 0 1 0-1.178Z' clip-rule='evenodd'/></svg>"));
  }
}

.accordion-item-solutions{
  a{
	&:hover{
	  color: $white;
	}
	&:before{
	  content:"";
	  position: relative;
	  display: block;
	  float: right;
	  top:5px;
	  width: 24px;
	  height: 25px;
	  margin-left: 4px;
	  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 21'><path fill='#{$white}' fill-rule='evenodd' d='M4.826 7.41a.833.833 0 0 1 1.179 0l4.41 4.411 4.411-4.41a.833.833 0 0 1 1.179 1.178l-5 5a.833.833 0 0 1-1.179 0l-5-5a.833.833 0 0 1 0-1.178Z' clip-rule='evenodd'/></svg>"));
	}
  }
}

.nav-solutions-bloc {
  .nav-solutions {
	&:before {
	  content: "";
	  display: block;
	  float: left;
	  height: calc(100% - 75px);
	  width: 5px;
	  background-color: $neutral-color-border;
	  border-radius: 5px;
	  position: absolute;
	  left: -10px;
	}
	a {
	  position: relative;
	  color: $neutral-placeholder;
	  
	  &.active {
		color: $secondary !important;
		
		&:before {
		  content: "";
		  display: block;
		  height: 100%;
		  width: 5px;
		  background-color: $secondary;
		  border-radius: 5px;
		  position: absolute;
		  left: -10px;
		  top: 0;
		}
	  }
	}
  }
}

.btn-demo-accueil{
  transition: all ease-in-out 0.2s;
  &:hover{
	background-color: $secondary;
	color:$white !important;
	transition: all ease-in-out 0.2s;
  }
}

.no-before{
  &:before{
	content: none;
  }
}

.form-check{
  input:checked + label{
	color: $primary !important;
  }
}