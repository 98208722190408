$primary : $love-purple-deep;
$secondary:     $jazzy-purple;
$neutre: #475569;
$estimation-vert: #DCF7ED;
$success:       $green;
$info:          $cyan;
$warning:       #FED440;
$danger:        $red;
$light:         $gray-100;
$dark:          #0F1A2A;

$text-chomage : #27364B;
$titre-chomage : #26335A;
$neutre-chomage : #F1F4F9;

$gradient-light: linear-gradient(325.25deg, rgba(210, 46, 120, 0.04) 0%, rgba(201, 182, 255, 0.035) 79.52%), #F8F7F7;
$gradient-dark: linear-gradient(333deg, rgba(210, 46, 120, 0.10) 0%, rgba(201, 182, 255, 0.10) 100%), #0F1A2A;

$theme-colors: ();
$theme-colors: map-merge(
	(
		"primary":    $primary,
		"secondary":  $secondary,
		"neutre":  $neutre,
		"neutral-color-border":  $neutral-color-border,
		"neutral-placeholder":  $neutral-placeholder,
		"love-purple-300":  $love-purple-300,
		"jazzy-purple-100":  $jazzy-purple-100,
		"jazzy-purple-200":  $jazzy-purple-200,
		"jazzy-purple-300":  $jazzy-purple-300,
		"jazzy-purple-500":  $jazzy-purple-500,
		"jazzy-purple-700":  $jazzy-purple-700,
		"jazzy-purple-800":  $jazzy-purple-800,
		"jazzy-purple-deep":  $jazzy-purple-deep,
		"chomage":  $text-chomage,
		"titre-chomage":  $titre-chomage,
		"neutre-chomage":  $neutre-chomage,
		"love-purple":  $love-purple,
		"love-purple-200":  $love-purple-200,
		"love-purple-400":  $love-purple-400,
		"love-purple-700":  $love-purple-700,
		"love-purple-800":  $love-purple-800,
		"estimation-vert":  $estimation-vert,
		"success":    $success,
		"info":       $info,
		"warning":    $warning,
		"danger":     $danger,
		"light":      $light,
		"dark":       $dark,
		"black":       $black,
		"white":       $white,
	),
	$theme-colors
);

/* ======= utilities
*/

$utilities: map-merge(
				$utilities,(
				"border-color": (
						property: border-color,
						class: border,
						local-vars: (
								"border-opacity": 1
						),
						values: $theme-colors
				),
		)
);