
.bg-degrade-primary{
  background: $gradient-dark;
}

.bg-degrade-light{
  background: $gradient-light;
}

.bg-cover{
  background-size: cover;
}

.bg-bandeau-alerte{

}