
html {
  font-size: .625rem; // 1rem = 10px with this line (easier calcul)
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.form-body {
  min-height: calc(100vh - 4.8rem);
}

.fill-love-purple svg path {
  fill: $love-purple !important;
}

.fill-primary svg path {
  fill: $primary !important;
}

.fill-jazzy-purple-500 svg path {
  fill: $jazzy-purple-500 !important;
}

.mea-detail-fill-love-purple a svg path:nth-child(1) {
  fill: $love-purple !important;
}

.fill-love-purple-200 svg path {
  fill: $love-purple-200 !important;
}

.fill-love-purple-deep svg path {
  fill: $love-purple-deep !important;
}

.mea-detail-fill-love-purple-700 a svg path:nth-child(1) {
  fill: $love-purple-700 !important;
}

.mea-decouvrez {
  .active {
	svg path {
	  fill: $love-purple-200 !important;
	}
	
	svg path:nth-child(1) {
	  fill: $love-purple-700 !important;
	}
  }
}

.mea-detail-fill-love-purple-no-link svg path:nth-child(1) {
  fill: $love-purple !important;
}

.tab-modules-details {
  li.uk-active {
    border-top: 5px solid $secondary;

    a {
      color: $secondary !important;
    }
  }

  li {
    @include media-breakpoint-up(md) {
      border-top: 5px solid $neutral-color-border;
    }
  }
}


.accordion-modules-details {
  .accordion-item {
    background: transparent !important;
    border-bottom: 1px solid $neutral-color-border;

    a.accordion-button {
      background: transparent !important;
      @include media-breakpoint-up(md) {
        padding: 30px 0;
      }
      @include media-breakpoint-down(md) {
        padding: 24px 0;
      }
      box-shadow: unset;
      color: $dark;
      font-family: map-get($fonts, urbanist);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 35px;

      &:after {
        background-size: 24px;
        height: 24px;
        width: 24px;
        background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 21'><path fill='#{$dark}' fill-rule='evenodd' d='M4.826 7.41a.833.833 0 0 1 1.179 0l4.41 4.411 4.411-4.41a.833.833 0 0 1 1.179 1.178l-5 5a.833.833 0 0 1-1.179 0l-5-5a.833.833 0 0 1 0-1.178Z' clip-rule='evenodd'/></svg>"));
      }
    }
  }
}

.gagner-du-temps {
  input {
    border-radius: 100% !important;
    cursor: pointer;
  }
}

.btn-etapes {
  &:before {
    content: "";
    display: inline-block;
    background-size: 20px;
    height: 20px;
    width: 20px;
    position: relative;
    top: 3px;
    margin-right: 6px;
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'><path fill-rule='evenodd' clip-rule='evenodd' d='M8.50848 4.91058C8.83392 5.23601 8.83392 5.76365 8.50848 6.08909L4.9311 9.6665H17.9193C18.3795 9.6665 18.7526 10.0396 18.7526 10.4998C18.7526 10.9601 18.3795 11.3332 17.9193 11.3332H4.9311L8.50848 14.9106C8.83392 15.236 8.83392 15.7637 8.50848 16.0891C8.18304 16.4145 7.6554 16.4145 7.32997 16.0891L2.33001 11.0891C2.00458 10.7637 2.00458 10.236 2.33001 9.91058L7.32997 4.91058C7.6554 4.58515 8.18304 4.58514 8.50848 4.91058Z' fill='#{$black}'/></svg>"));
  }
}

.btn-etapes-suivante {
  &:before {
    display: none;
  }

  &:after {
    content: "";
    display: inline-block;
    background-size: 20px;
    height: 20px;
    width: 20px;
    position: relative;
    top: 3px;
    margin-left: 6px;
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'><path fill-rule='evenodd' clip-rule='evenodd' d='M11.9082 4.91058C12.2336 4.58514 12.7613 4.58515 13.0867 4.91058L18.0866 9.91058C18.4121 10.236 18.4121 10.7637 18.0866 11.0891L13.0867 16.0891C12.7613 16.4145 12.2336 16.4145 11.9082 16.0891C11.5827 15.7637 11.5827 15.236 11.9082 14.9106L15.4856 11.3332H2.4974C2.03716 11.3332 1.66406 10.9601 1.66406 10.4998C1.66406 10.0396 2.03716 9.6665 2.4974 9.6665H15.4856L11.9082 6.08909C11.5827 5.76365 11.5827 5.23601 11.9082 4.91058Z' fill='#{$white}'/></svg>"));
  }
}

.hand {
  cursor: pointer;
}

.mea-decouvrez {
  svg path {
    transition: 0.2s all ease-in-out;
  }

  svg path:nth-child(1) {
    transition: 0.2s all ease-in-out;
  }

  a:hover {
    svg path {
      transition: 0.2s all ease-in-out;
      fill: $love-purple-200 !important;
    }

    svg path:nth-child(1) {
      transition: 0.2s all ease-in-out;
      fill: $love-purple-700 !important;
    }
  }
}

.modules-accueil{
  a:hover {
	svg path:nth-child(1) {
	  transition: 0.2s all ease-in-out;
	  fill: $love-purple !important;
	}
  }
}

.deco-btn-demo{
  bottom:50px;
  right:40px
}

// message d'alerte sur les formulaires

#alert-warning{
  display: none;
  opacity: 0;
}