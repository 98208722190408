.deco-titre{
  @include media-breakpoint-down(xxxl) {
	top:-30px;
	left:-30px;
	width:34px;
  }
  @include media-breakpoint-up(xxxl) {
	top:-50px;
	left:-70px
  }
}

.chomage-hugo{
  @include media-breakpoint-up(lg) {
	left:-50px;
	top:40px;
  }
  @include media-breakpoint-up(xxxl) {
	left:90px;
	top:40px;
  }
  @include media-breakpoint-down(lg) {
	left:0px;
	top:60px;
	height:229px;
  }
  @include media-breakpoint-down(sm) {
	left:-20px;
	top:40px;
	height:133px;
  }
}

.chomage-hugo-deco{
  @include media-breakpoint-up(lg) {
	left:140px;
	top:350px;
  }
  @include media-breakpoint-up(xxxl) {
	left:280px;
	top:350px;
  }
  @include media-breakpoint-down(lg) {
	left:115px;
	top:225px;
	width: 108px;
  }
  @include media-breakpoint-down(sm) {
	left:40px;
	top:130px;
	width: 62px;
  }
}

.chomage-nico{
  @include media-breakpoint-up(lg) {
	right:-65px;
	top:-30px;
  }
  @include media-breakpoint-up(xxxl) {
	right:60px;
	top:-30px;
  }
  @include media-breakpoint-down(lg) {
	right:-25px;
	top:43px;
	height: 247px;
  }
  @include media-breakpoint-down(sm) {
	right:-25px;
	top:43px;
	height: 143px;
  }
}

.chomage-nico-deco{
  @include media-breakpoint-up(lg) {
	right:220px;
	top:60px;
  }
  @include media-breakpoint-up(xxxl) {
	right:343px;
	top:60px;
  }
  @include media-breakpoint-down(lg) {
	right:130px;
	top:95px;
	width: 30px;
  }
  @include media-breakpoint-down(sm) {
	right:65px;
	top:75px;
	width: 17px;
  }
}

.chomage-sidney{
  @include media-breakpoint-up(lg) {
	right:-160px;
	bottom:100px;
  }
  @include media-breakpoint-up(xxxl) {
	right:120px;
	bottom:-10px;
  }
  @include media-breakpoint-down(lg) {
	left:35%;
	top:85px;
	height: 182px;
  }
  @include media-breakpoint-down(sm) {
	left:30%;
	top:55px;
	height: 106px;
  }
}

.chomage-sidney-deco{
  @include media-breakpoint-up(lg) {
	right:20px;
	bottom:370px;
  }
  @include media-breakpoint-up(xxxl) {
	right:320px;
	bottom:260px;
  }
  @include media-breakpoint-down(lg) {
	left:45%;
	top:60px;
	width:66px;
  }
  @include media-breakpoint-down(sm) {
	left:41%;
	top:40px;
	width:38px;
  }
}