
.uk-slidenav svg + svg{
  display: none;
}

.nav-contact{
  border-bottom:4px solid $neutral-color-border;
  li.uk-active{
	border-bottom:4px solid $love-purple;
	a{
	  color:$love-purple-deep;
	}
  }
  li{
	position: relative;
	top:4px;
	a{
	  font-size: 18px;
	  font-weight:700;
	  line-height: 27px;
	  width:100%;
	  display: block;
	  padding-bottom:4px;
	  color: $neutral-placeholder;
	}
  }
}

.uk-light .uk-dotnav > * > * {
	border-color: $primary;
	width: 15px;
	height: 15px;
}


.uk-light .uk-dotnav > .uk-active > * {
	@extend .bg-primary;
}

.uk-accordion-title::before {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC44MjcgNi45MWEuODMzLjgzMyAwIDAgMSAxLjE3OSAwbDQuNDEgNC40MTEgNC40MTEtNC40MWEuODMzLjgzMyAwIDEgMSAxLjE3OSAxLjE3OGwtNSA1YS44MzMuODMzIDAgMCAxLTEuMTc5IDBsLTUtNWEuODMzLjgzMyAwIDAgMSAwLTEuMTc4WiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");
}

.uk-open > .uk-accordion-title::before {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC44MjcgNi45MWEuODMzLjgzMyAwIDAgMSAxLjE3OSAwbDQuNDEgNC40MTEgNC40MTEtNC40MWEuODMzLjgzMyAwIDEgMSAxLjE3OSAxLjE3OGwtNSA1YS44MzMuODMzIDAgMCAxLTEuMTc5IDBsLTUtNWEuODMzLjgzMyAwIDAgMSAwLTEuMTc4WiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");
	transform: rotate(180deg);
}